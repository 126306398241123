'use client';

import { useSearchParams } from 'next/navigation';
import { signIn, useSession } from 'next-auth/react';
import { useEffect } from 'react';

export default function SignInWithOkta() {
  const { status } = useSession();
  const searchParams = useSearchParams();
  const callbackUrl = searchParams.get('callbackUrl') ?? '/';

  useEffect(() => {
    if (status === 'unauthenticated') {
      signIn('okta', { callbackUrl, redirect: true });
    }
  }, [status]);

  return <></>;
}
